import { color, media } from "@sencrop/ui-components";
import { WebText, WebTitle, webtext } from "@sencrop/ui-web";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import ImageElement from "../components/ImageElement";
import { usePage } from "../providers/PageProvider";
import LinkElement from "../containers/LinkElement";
import { containerCss, contentCss } from "../helpers/style";
import { useURLsWithUTMs } from "../containers/hooks/useURLsWithUTMs";

const SectionHero404 = ({
  errorTitle,
  errorText,
  parts,
  link,
  backgroundImage,
  homePageLabel,
}: GatsbyTypes.SectionHero404FragmentFragment) => {
  const { l } = usePage();
  const [rootUrl] = useURLsWithUTMs([l("/")]);
  return (
    <Background>
      <Wrapper>
        {errorTitle && (
          <TitleContent>
            {errorTitle?.childMarkdownRemark?.rawMarkdownBody && (
              <WebTitle
                as="h1"
                sizes={["xl", "xxl", "xxl", "xxl"]}
                color="branding-secondary"
                style={{ marginBottom: "0.5rem", whiteSpace: "pre-wrap" }}
              >
                {errorTitle.childMarkdownRemark.rawMarkdownBody}
              </WebTitle>
            )}
          </TitleContent>
        )}
        <Grid>
          <div>
            {errorText?.childMarkdownRemark?.html && (
              <MainText
                dangerouslySetInnerHTML={{
                  __html: errorText.childMarkdownRemark.html,
                }}
                style={{ whiteSpace: "pre-wrap" }}
              />
            )}
            <LinksContainer>
              {homePageLabel && (
                <HomeLink as={Link} to={rootUrl}>
                  <LinkElement>{homePageLabel}</LinkElement>
                </HomeLink>
              )}
              {link && <LinkElement link={link}>{link.label}</LinkElement>}
            </LinksContainer>
          </div>
          {parts && (
            <PartsContainer>
              {parts.map((part, index) => {
                return (
                  <div key={index}>
                    {part?.partTitle && (
                      <WebText color="primary" style={{ fontWeight: "bold" }}>
                        {part.partTitle}
                      </WebText>
                    )}
                    {part?.text?.childMarkdownRemark?.html && (
                      <TextContent
                        dangerouslySetInnerHTML={{
                          __html: part.text.childMarkdownRemark.html,
                        }}
                        style={{ whiteSpace: "pre-wrap" }}
                      />
                    )}
                  </div>
                );
              })}
            </PartsContainer>
          )}
        </Grid>
        {backgroundImage && (
          <ImageWrapper>
            <ImageElement
              gatsbyImageData={backgroundImage.gatsbyImageData}
              alt="Background 404"
              style={{ height: "100%", width: "100%" }}
            />
          </ImageWrapper>
        )}
      </Wrapper>
    </Background>
  );
};

export default SectionHero404;

export const fragment = graphql`
  fragment SectionHero404Fragment on ContentfulSectionHero404 {
    errorTitle: title {
      childMarkdownRemark {
        rawMarkdownBody
      }
    }
    errorText: text {
      childMarkdownRemark {
        html
      }
    }
    parts {
      partTitle: title
      text {
        childMarkdownRemark {
          html
        }
      }
    }
    homePageLabel
    link {
      ...LinkFragment
    }
    backgroundImage {
      gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
`;

const Background = styled.div`
  background: ${color("background-primary")};
`;

const Wrapper = styled.div`
  ${containerCss}
  ${contentCss}
  padding-top: 6rem;
  padding-bottom: 11.25rem;
  position: relative;
  ${media.greaterThan("tablet")`
    padding-top: 8rem;
    padding-bottom: 16rem;
  `}
`;

const TitleContent = styled.div`
  text-align: center;
  ${media.greaterThan("tablet")`
    text-align: left;
  `}
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 2rem;
  z-index: 2;
  * {
    z-index: 2;
  }
  ${media.greaterThan("tablet")`
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  `}
`;

const MainText = styled.div`
  p {
    color: ${color("text-primary:75")};
    ${webtext("text", "l")};
  }
  margin-bottom: 1.125rem;
  text-align: center;
  ${media.greaterThan("tablet")`
    text-align: left;
    margin-bottom: 1.625rem;
  `}
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  > *:nth-child(2) {
    margin-left: 2rem;
  }
  ${media.greaterThan("tablet")`
     justify-content: flex-start;
  `}
`;

const HomeLink = styled.div``;

const TextContent = styled.div`
  p {
    color: ${color("text-primary")};
    ${webtext("text", "m")};
  }
`;

const PartsContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  ${media.greaterThan("tablet")`
    grid-gap: 1.5rem;
  `}
`;

const ImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 11.25rem;
  img {
    object-fit: contain !important;
  }
  ${media.greaterThan("tablet")`
    height: 20rem;
  `}
`;
